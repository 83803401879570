import { parse } from '@conform-to/zod';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  type DataFunctionArgs,
  type HeadersFunction,
  json,
  type LinksFunction,
  type MetaFunction } from
'@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetchers,
  useLoaderData } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { EpicProgress } from './components/progress-bar.tsx';
import { EpicToaster } from './components/toaster.tsx';
import { href as iconsHref } from './components/ui/icon.tsx';
import fontStyleSheetUrl from './styles/font.css';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getUserId, logout } from './utils/auth.server.ts';
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx';
import { csrf } from './utils/csrf.server.ts';
import { prisma } from './utils/db.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { useRequestInfo } from './utils/request-info.ts';
import { getTheme, setTheme, type Theme } from './utils/theme.server.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';
import { NavigationBar } from '#app/components/navigation-bar.tsx';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  { rel: 'stylesheet', href: fontStyleSheetUrl },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'Neutrino Graphics' : 'Error | Neutrino Graphics' },
  // TODO: Update the description
  { name: 'description', content: `Your own captain's log` }];

};

export async function loader({ request }: DataFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUniqueOrThrow({
      select: {
        id: true,
        name: true,
        username: true,
        image: { select: { id: true } },
        roles: {
          select: {
            name: true,
            permissions: {
              select: { entity: true, action: true, access: true }
            }
          }
        }
      },
      where: { id: userId }
    }),
    { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;
  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      csrfToken
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark'])
});

export async function action({ request }: DataFunctionArgs) {
  const formData = await request.formData();
  const submission = parse(formData, {
    schema: ThemeFormSchema
  });
  if (submission.intent !== 'submit') {
    return json(({ status: 'idle', submission } as const));
  }
  if (!submission.value) {
    return json(({ status: 'error', submission } as const), { status: 400 });
  }
  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setTheme(theme) }
  };
  return json({ success: true, submission }, responseInit);
}

function Document({
  children,
  nonce,
  theme = 'light',
  env = {}





}: {children: React.ReactNode;nonce: string;theme?: Theme;env?: Record<string, string>;}) {
  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Links />
			</head>
			<body className="bg-background text-foreground transition-colors duration-200">
				{children}
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();

  return (
    <Document nonce={nonce} theme={theme} env={data.ENV}>
			<header className="fixed left-0 top-0 w-full">
				<NavigationBar userPreference={data.requestInfo.userPrefs.theme} />
			</header>

			<GoogleReCaptchaProvider
        reCaptchaKey={data.ENV.RECAPTCHA_SITE_KEY}
        scriptProps={{
          async: false,
          defer: true,
          appendTo: 'head',
          nonce: undefined
        }}>

				<Outlet />
			</GoogleReCaptchaProvider>

			<EpicToaster toast={data.toast} />
			<EpicProgress />
		</Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }
  return requestInfo.userPrefs.theme ?? hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  if (themeFetcher && themeFetcher.formData) {
    const submission = parse(themeFetcher.formData, {
      schema: ThemeFormSchema
    });
    return submission.value?.theme;
  }
}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}