import { Link, useFetcher } from '@remix-run/react/dist/index.js'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '../components/ui/dropdown-menu.js'
import { useState } from 'react'
import { type Theme } from '#app/utils/theme.server.js'
import { useForm } from '@conform-to/react'
import { Icon } from './ui/icon.js'
import { ErrorList } from './forms.js'
import { type action, useOptimisticThemeMode } from '../root.js'
import UnderlineBar from '#app/components/underline-bar.tsx'
import { useLocation } from '@remix-run/react'

enum RoutePath {
	home = '/',
	privacy = '/privacy',
	contact = '/contact-us',
}

export function NavigationBar({
	userPreference,
}: {
	userPreference?: Theme | null
}) {
	const location = useLocation()
	const [isOpen, setIsOpen] = useState(false)

	return (
		<nav className="px-5 py-5 backdrop-blur-sm lg:px-10">
			<div className="flex flex-wrap items-center justify-between gap-4 lg:flex-nowrap ">
				<Link to={RoutePath.home}>Neutrino Graphics</Link>

				{/* Navigation Menu */}
				<div className="hidden items-center space-x-5 lg:flex">
					<Link to={RoutePath.home} className="group mt-0.5">
						Home{' '}
						<UnderlineBar
							startFrom={'center'}
							className={`${
								location.pathname === RoutePath.home && 'max-w-full'
							}`}
						/>
					</Link>
					<Link to={RoutePath.privacy} className="group mt-0.5">
						Privacy Policy{' '}
						<UnderlineBar
							startFrom={'center'}
							className={`${
								location.pathname === RoutePath.privacy && 'max-w-full'
							}`}
						/>
					</Link>
					<Link
						to={RoutePath.contact}
						className="w-fit rounded bg-indigo-400 px-4 py-2 transition-colors duration-200 hover:bg-indigo-700"
					>
						Contact
					</Link>

					<ThemeSwitch userPreference={userPreference} />
				</div>

				{/*	Dropdown Menu */}
				{/* Only Rendered on small phone screen */}
				<div className="lg:hidden">
					<DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
						<DropdownMenuTrigger asChild>
							<button className="space-y-1">
								<div
									className={`h-1 w-5 rounded-full bg-primary ${
										isOpen && 'translate-y-2 rotate-45'
									} transition-all duration-200`}
								/>
								<div
									className={`h-1 w-5 rounded-full bg-primary ${
										isOpen && 'invisible opacity-0'
									} transition-all duration-200`}
								/>
								<div
									className={`h-1 w-5 rounded-full bg-primary ${
										isOpen && '-translate-y-2 -rotate-45'
									} transition-all duration-200`}
								/>
							</button>
						</DropdownMenuTrigger>

						<DropdownMenuPortal>
							<DropdownMenuContent align={'end'}>
								<DropdownMenuItem asChild>
									<Link to="/">Home</Link>
								</DropdownMenuItem>

								<DropdownMenuItem asChild>
									<Link to="/privacy">Privacy Policy</Link>
								</DropdownMenuItem>

								<DropdownMenuSeparator />
								<DropdownMenuItem asChild>
									<Link to="/contact-us">Contact</Link>
								</DropdownMenuItem>

								<DropdownMenuSeparator />
								<DropdownMenuItem>
									<ThemeSwitch userPreference={userPreference} />
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenuPortal>
					</DropdownMenu>
				</div>
			</div>
		</nav>
	)
}

function ThemeSwitch({ userPreference }: { userPreference?: Theme | null }) {
	const fetcher = useFetcher<typeof action>()

	const [form] = useForm({
		id: 'theme-switch',
		lastSubmission: fetcher.data?.submission,
	})

	const optimisticMode = useOptimisticThemeMode()
	const mode = optimisticMode ?? userPreference ?? 'system'
	const nextMode =
		mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system'
	const modeIcon = {
		light: (
			<Icon name="sun">
				<span className="sr-only">Light</span>
			</Icon>
		),
		dark: (
			<Icon name="moon">
				<span className="sr-only">Dark</span>
			</Icon>
		),
		system: (
			<Icon name="laptop">
				<span className="sr-only">System</span>
			</Icon>
		),
	}
	const modeLabel = {
		light: 'Light',
		dark: 'Dark',
		system: 'System',
	}

	return (
		<fetcher.Form method="POST" {...form.props} className="w-full lg:w-fit">
			<input type="hidden" name="theme" value={nextMode} />
			<div className="flex gap-2">
				<button
					type="submit"
					className="flex h-4 w-full cursor-pointer items-center gap-2 lg:h-8 lg:justify-center"
				>
					{modeIcon[mode]}

					<span className="lg:hidden">{modeLabel[mode]}</span>
				</button>
			</div>
			<ErrorList errors={form.errors} id={form.errorId} />
		</fetcher.Form>
	)
}
