import React from 'react'

type Props = {
	startFrom: 'left' | 'center'
	className?: string
}

/**
 * Animated component for underline
 * @param startFrom "left" | "center" props to determine where the animation starts
 * @param className additional classname for conditional formatting
 * */

function UnderlineBar({ startFrom, className }: Props) {
	const animationStartPoint = startFrom === 'center' ? 'mx-auto' : ''
	return (
		<span
			className={`block bg-primary ${animationStartPoint} h-0.5 max-w-[0rem] transition-all duration-300 group-hover:max-w-full ${className}`}
		></span>
	)
}

export default UnderlineBar
